import React, { Component, Fragment } from "react";
import NotFound from "../components/NotFound";
import Helmet from "react-helmet";
import Config from '../../data/SiteConfig';

export default class NotFoundPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet title={["Sidan kunde inte hittas", Config.siteTitle].join(' | ')} />

        <NotFound />
      </Fragment>
    );
  }
}
