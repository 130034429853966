import React, { Component } from "react";
import "./index.scss";

export default class NotFound extends Component {
  render() {
    return (
      <div className="notfound_wrapper">
        <div className="notfound">
          <div className="notfound__img_txt">404</div>

          <p className="notfound__txt">
            Sidan kunde inte hittas
          </p>
        </div>
      </div>
    );
  }
}
